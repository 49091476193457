import React, { useCallback,useMemo, useState } from 'react'
import PropTypes from "prop-types"
import classNames from 'classnames/bind'
import styles from '@styles/components/profile_modal.module.scss'
import Nl2br from '@comp/util/nl2br'


const cx = classNames.bind(styles)

const propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  profimg: PropTypes.bool.isRequired,
  position: PropTypes.string|| null,
  profile: PropTypes.string|| null,
  graduate: PropTypes.string|| null,
  yummy: PropTypes.string|| null,
  industry: PropTypes.string|| null,
  background: PropTypes.string|| null,
  handleClickClose: PropTypes.func.isRequired
}

const ProfileModal = ({
  id,
  name,
  profimg,
  position,
  profile,
  graduate,
  yummy,
  industry,
  background,
  handleClickClose
}) => {
  const [isClose, setIsClose] = useState(false)

  const classNameWrap = cx({
    wrap: true,
    isClose: isClose
  })

  const onClickClose = useCallback( (evt) => {
    evt.preventDefault()
    setIsClose(true)
    setTimeout(handleClickClose,700)
  },[handleClickClose])

  const profImgSrc = useMemo( () => {
    return profimg ? `/images/member/member-img-${id}.jpg` : `/images/member/member-img-noimage.png`
  },[id])

  return (
    <>
      <div className={classNameWrap}>
        <div className={styles.inner}>
          <button onClick={onClickClose} className={styles.btnClose}>
            <span className={styles.btnClose__icn}></span>
          </button>
          <section className={styles.contents}>
            <div className={styles.head}>
              <div className={styles.img}>
                <span className={styles.yummy}>
                  {
                    yummy ? <img src={`/images/member/yummy/${yummy}2.png`} alt={`${yummy} ${yummy}`} width="260" height="260" /> : null
                  }
                </span>
                <img src={profImgSrc} alt={name}/>
              </div>
              <div className={styles.detail}>
                <h1 className={styles.name}>{name}</h1>
                <p className={styles.position}><Nl2br txt={position} /></p>
                <p className={styles.info}>
                  <span className={styles.info__label}>
                    <span className={styles.info__label__txt}>INDUSTRY</span>
                  </span>
                  <span className={styles.info__txt}>
                    <Nl2br txt={industry} />
                  </span>
                </p>
                <p className={styles.info}>
                  <span className={styles.info__label}>
                    <span className={styles.info__label__txt}>BACK<br />GROUND</span>
                  </span>
                  <span className={styles.info__txt}>
                    <Nl2br txt={background} />
                  </span>
                </p>       
              </div>
            </div>
            <div className={styles.body}>
              <p className={styles.profile}><Nl2br txt={profile} /></p>
              {
                graduate ? (
                  <dl className={styles.graduate}>
                    <dt className={styles.graduate__title}>学歴等</dt>
                    <dd className={styles.graduate__txt}>
                      <Nl2br txt={graduate} />
                    </dd>
                  </dl>
                ) : null
              }
            </div>
          </section>
        </div>
      </div>
    </>
  )
}
ProfileModal.propTypes = propTypes
export default ProfileModal