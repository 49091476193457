import React, { useCallback,useMemo,useState } from 'react'
import PropTypes from "prop-types"
import classNames from 'classnames/bind'
import styles from '@styles/components/member_nav.module.scss'

import IcnCheck from '@comp/atom/icn_check'
import BtnBox from '@comp/btn_box'

import navList from '@const/project_list'

const cx = classNames.bind(styles)

const propTypes = {
  selectedProject: PropTypes.array.isRequired,
  toggleSelectProject: PropTypes.func.isRequired,
  setSelectedProject: PropTypes.func.isRequired
}

const MemberNav = ({
  selectedProject,
  toggleSelectProject,
  setSelectedProject
}) => {
  const [isNavOpen, setIsNavOpen] = useState(false)
  const [oldSelected, setOldSelected] = useState(null)

  const onClickBtn = useCallback( (id) => {
    toggleSelectProject(id)
  },[toggleSelectProject])

  const setNavStatus = useCallback( (status) => {
    setIsNavOpen(status)
  },[setIsNavOpen])
  
  const onClickExpand = useCallback( () => {
    setNavStatus(true)
    setOldSelected(selectedProject)
  },[setNavStatus,selectedProject])

  const onClickClose = useCallback( () => {
    setNavStatus(false)
    setSelectedProject(oldSelected)
    setOldSelected(null)
  },[setNavStatus, setSelectedProject, oldSelected])

  const onClickSubmit = useCallback( () => {
    setNavStatus(false)
  },[setNavStatus])

  const classNameWrap = cx({
    wrap: true,
    isOpen: isNavOpen
  })

  const navElms = useMemo( () => {
    return navList.map( (itm,index) => {
      const isChecked = selectedProject.includes(itm.id)
      return (
        <li key={index} className={styles.list__itm}>
          <button
            onClick={(evt) => {
              evt.preventDefault()
              onClickBtn(itm.id)
            }} 
            className={styles.list__btn}
            data-selected={isChecked}
          >
            <span className={styles.list__label}>{itm.label}</span>
            <span className={styles.list__checkbox}>
              <span className={styles.list__checkbox__icn}><IcnCheck /></span>
            </span>
          </button>
        </li>
      )
    })
  },[onClickBtn, selectedProject])

  return (
    <>
      <button onClick={onClickExpand} className={styles.btn}>
        担当事業で絞り込む
        <span className={styles.btn__icn}></span>
      </button>

      <div className={classNameWrap}>
        <div className={styles.inner}>
          <div className={styles.box}>
            <p className={styles.overview}>各ブランド名から担当者をソートすることができます。</p>
            <ul className={styles.list}>
              { navElms }
            </ul>

            <div className={styles.submit}>
              <BtnBox
                onClick={onClickSubmit}
              >
                OK
              </BtnBox>
            </div>
          </div>  
        </div>
        <button onClick={onClickClose} className={styles.close} aria-label="閉じる">
          <span className={styles.close__icn}></span>
        </button>
      </div>
    </>
  )
}

MemberNav.propTypes = propTypes
export default MemberNav