import React from "react"

const IcnCheck = () => {
  return (
    <svg viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M1 3L4 6L9 1" stroke="black" strokeWidth="1.5"/>
    </svg>
  )
}

export default IcnCheck
