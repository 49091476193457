/**
 * project_id 対応番号
 * project_list.jsにて設定
 * 
 * 1: 未来日本酒店 / 未来酒店
 * 2: YUMMY SAKE
 * 3: CHEERSAKE // 削除のため使用不可
 * 4: MIRAI SAKE INTERNATIONAL
 * 5: MIRAI SAKE BREWING
 * 6: 酒造好適米生産事業
 * 7: 未来酒蔵承継機構
 * 8: MIRAI SAKE CONSULTING
 * 
 */


export default [
  {
    id: 1,
    name: '山本 祐也',
    profimg: true,
    position: 'グループCEO',
    profile: '1985年生、石川県出身。2014年より日本酒産業での取り組みをスタート、一次産業である酒米生産(佐渡島)、二次産業である委託醸造(11銘柄)、三次産業である日本酒小売専門店経営にそれぞれ取り組む。これらを有機的に繋げて運営していることが評価され、2018年に6次産業化推進事業者として認定を受ける。\n過去にはAKB48プロジェクトの運営会社にて事業開発を担当、同社の衣装・ヘアメイク部門のスピンオフ会社の立ち上げ及び新潟における同プロジェクトの新グループ立ち上げを行う。 それ以前は、野村證券及びJPモルガン証券にて投資銀行業務に従事。',
    graduate: '一橋大学社会学部卒 \nケンブリッジ大学大学院MBA \n酒匠 \n唎酒師(SSI認定)',
    yummy: 'puri',
    industry: '投資銀行\nエンターテインメント',
    background: 'イギリス(ケンブリッジ)\n石川県',
    project: [
      1,2,4,5,6,7,8
    ]
  },
  {
    id: 2,
    name: '寺田 祐貴',
    profimg: true,
    position: 'グループ取締役 管理部門管掌\n兼 未来日本酒店事業副管掌',
    profile: '1985年生、宮城県出身。2009年に(株)三越伊勢丹へ入社後、輸入衣料品を扱うインターナショナルラグジュアリーを担当し、伊勢丹流の販売とマーチャンダイジングの基礎を学ぶ。\nその後アンダーウェアからレザーグッズまで様々な領域で売場づくり及び商品開発を手掛け、その手腕を発揮、2016年度より同社紳士統括部の計画・新規担当として新規出店など事業立案に従事する。',
    graduate: '慶應義塾大学商学部卒',
    yummy: 'yawa',
    industry: '百貨店',
    background: '宮城県',
    project: [
      1,2,5
    ]
  },
  {
    id: 3,
    name: '山本 慎吾',
    profimg: true,
    position: 'グループ執行役員\n未来日本酒店事業管掌',
    profile: '1979年生、東京都出身。2002年に株式会社伊勢丹に入社。伊勢丹新宿店の服飾雑貨営業部を経て同店の食品営業部に配属。2011年に大阪での新規店舗の立ち上げ及びオープン後のマネジメントを行い、2016年以降は伊勢丹新宿店や三越日本橋本店の和洋酒のバイヤーを担当。2020年4月に株式会社未来酒店に入社。店舗運営、店舗戦略を推進するとともに酒蔵とのネットワークの構築や新規事業の立ち上げと運営に従事。',
    graduate: '慶応義塾大学商学部卒',
    yummy: 'puri',
    industry: '百貨店',
    background: '東京都',
    project: [
      1,5,6
    ]
  },
  {
    id: 4,
    name: 'てるじい',
    profimg: true,
    position: 'グループ戦略営業室長 \n兼 未来酒蔵承継機構 共同代表',
    profile: '年間１万種近く全国の日本酒をテイスティングする日本酒ソムリエ。 福岡県久留米市出身。28歳の時に日本酒の良さ素晴らしさを広める為、はせがわ酒店に入社。5年間勤め日本酒のベースを学ぶ。 飲み手との距離の近い飲食店の魅力に惹かれ、荻窪いちべえで3年間店長を勤め、その後麦酒庵 恵比寿店の立ち上げから5年間店長を勤め、食べログ「恵比寿 居酒屋 ランキング１位」のお店にする。\n造り手視点の酒(科学的分析)を勉強する為、利き酒マイスター取得後、清酒官能評価者の実技講習合格。現在はフリーランスで、酒販店飲食店酒蔵のコンサルタント、イベンター(様々なジャンルのシェフと日本酒のマリアージュイベントを開催)、として活躍。 自然・生産者・消費者、全ての人々が幸せになるワークサイクルを目指している。',
    graduate: null,
    yummy: null,
    industry: '酒販店',
    background: '福岡県',
    project: [
      5,7
    ]
  },
  {
    id: 5,
    name: '大山 慧士郎',
    profimg: true,
    position: 'リードエンジニア',
    profile: '1995年生まれ。慶應義塾大学卒業。LINE株式会社でサービスの企画・開発ディレクションを経験したのち、2021年3月よりREADYFOR株式会社にプロダクトマネージャーとして参画。フリーランスとしても精力的に活動しており、当社ではリードエンジニアとして従事。サービスの開発とグロースに尽力している。',
    graduate: null,
    yummy: null,
    industry: 'IT',
    background: '東京都',
    project: []
  },
  {
    id: 6,
    name: '上田 和真',
    profimg: true,
    position: 'デジタル部門担当ディレクター',
    profile: '1993年生。兵庫県出身。日本の高校を卒業後、アメリカ シアトルの大学に進学。大学を中退して日本に帰国した後、カナダ発の2週間で消えるタトゥー「インクボックス」の日本事業立ち上げなどに携わる。\n2018年にフランスの化粧品会社ロクシタンジャポンに転職。デジタルマーケティングを中心にECや店舗の売上・利益改善に貢献。2021年独立。',
    graduate: null,
    yummy: 'kurun',
    industry: '化粧品、雑貨',
    background: '兵庫県',
    project: [
      1,8
    ]
  },
  {
    id: 7,
    name: '山崎 毅志',
    profimg: true,
    position: '未来酒店部門 管理統括',
    profile: '1981年生まれ、福岡県出身。2000年に俳優を目指すため上京。\n俳優業から倉庫業の不動産会社に転職し2017年まで勤務後、日本酒好きが高じて日本酒専門の酒販店へ転職。その後、2019年9月より株式会社未来酒店勤務。お客様の要望に寄りそう対応をし、風呂敷包みなどラッピングを得意としています。',
    graduate: null,
    yummy: 'yawa',
    industry: '酒販店',
    background: '福岡県',
    project: [
      1
    ]
  },
  {
    id: 9,
    name: '謝 翠翠',
    profimg: true,
    position: '未来日本酒店渋谷店長',
    profile: '1988年生まれ、台湾高雄出身。2012年から台湾で日本酒の販売、コンサルティングを行う。また、MICE関連業務にも従事。2017年に来日し、日本酒輸出商社に勤務。2018年より株式会社未来酒店勤務。渋谷パルコ店店長を務めながら、日本酒の海外輸出も担当してます。',
    graduate: '文藻外語大学日本語学科卒 中国語教育学科修士課程修了',
    yummy: 'pata',
    industry: '酒販店',
    background: '台湾(高雄)',
    project: [
      1
    ]
  },
  {
    id: 12,
    name: '三島 龍',
    profimg: false,
    position: '未来酒店監査役',
    profile: '1964年生、東京都出身。1988年にJPモルガンに入社の後、東京・ニューヨーク・ロンドンで資本市場・投資銀行業務に従事。\nモルガン・スタンレー、ドイツ証券、BNPパリバを経て、2015年にファイナンスやマーケティングのコンサルティングを行う株式会社レゾナンスを設立し、代表取締役に就任。2016年にはベンチャーキャピタルである株式会社アルコパートナーズの取締役に就任するなど、スタートアップ企業への幅広い支援・出資を行っている。',
    graduate: '東京大学経済学部卒\n(社)TXアントレプレナーパートナーズ・エンジェル会員',
    yummy: null,
    industry: '投資銀行、ベンチャーキャピタル',
    background: '東京都',
    project: [
      1
    ]
  },
  {
    id: 13,
    name: '小嶋 祐樹',
    profimg: false,
    position: 'グループ執行役員\n兼 MIRAI SAKE CONSULTING事業管掌',
    profile: '1987年生。2012年に弁護士登録後、外資系法律事務所で国際企業法務を専門とした弁護士業に従事。2019年8月にMirai Sake Companyに参画後は、同社の海外展開および新規事業展開に従事',
    graduate: 'INSEAD卒、経営学修士（MBA）\n慶應義塾大学法学部法律学科卒 \n株式会社酒物語 代表取締役 \n山形商工会議所会員',
    yummy: 'byun',
    industry: '弁護士',
    background: '山形県',
    project: [
      7,8
    ]
  }
]