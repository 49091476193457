import React, { useCallback, useMemo, useState } from 'react'

import styles from '@styles/pages/member.module.scss'

import Layout from '@comp/layout'
import SEO from '@comp/seo'

import MemberNav from '@comp/member_nav'
import PageTitle from '@comp/page_title'
import ProfileBox from '@comp/profile_box'
import ProfileModal from '@comp/profile_modal'
import RecruitBlock from '@comp/recruit_block'

import Members from '@const/member_list'

// markup
const MemberPage = ({ location }) => {

  const [currentProfile, setCurrentProfile] = useState(null)
  const [selectedProject, setSelectedProject] = useState([])

  const checkSelectedProject = useCallback( (project) => {
    const selectedLength = selectedProject.length > 0
    return selectedLength ? [...project,...selectedProject].filter( (itm) => {
      return project.includes(itm) && selectedProject.includes(itm)
    }).length > 0 : true
  },[selectedProject])

  const toggleSelectProject = useCallback( (projectId) => {
    const newArray = [...selectedProject]
    const index = newArray.indexOf(projectId)
    if( index < 0 ) {
      newArray.push(projectId)
    } else {
      newArray.splice(index,1)
    }
    setSelectedProject(newArray)
  },[selectedProject,setSelectedProject])

  const handleClickProfile = useCallback( (id) => {
    const targetProfile = Members.find( (member) => {
      return member.id === id
    })
    setCurrentProfile(targetProfile)
  },[setCurrentProfile])

  const handleClickModalClose = useCallback( () => {
    setCurrentProfile(null)
  },[setCurrentProfile])

  const memberItms = useMemo( () => {
    return Members.map( (member) => {
      const isVisible = checkSelectedProject(member.project)
      return <ProfileBox key={member.id} data={member} handleClick={handleClickProfile} isVisible={isVisible} />
    })
  },[checkSelectedProject, handleClickProfile])

  return (
    <Layout location={location} pageName='member'>
      <SEO
        title={`メンバー`}
        description={`MIRAI SAKE COMPANYには、SAKEの未来を創るべく、多様なバックグラウンドを持つ人材が集まっています。`}
        path={location.pathname}
      />
      <main className={styles.container}>
        <div className={styles.inner}>
          <PageTitle
            label='メンバー'
            labelEn='MEMBER'
          />
          <MemberNav
            selectedProject={selectedProject}
            toggleSelectProject={toggleSelectProject}
            setSelectedProject={setSelectedProject}
          />

          <ul className={styles.profile}>
            { memberItms }
          </ul>
        </div>
        
        <div className={styles.inner}>
          <div className={styles.recruit}>
            <RecruitBlock />
          </div>
        </div>

        <div className={styles.group_img}>
          <picture>
            <source
              media="(max-width:993px)"
              srcSet="
                /images/home/sp/home-img-member01.jpg,
              "
            />
            <img
              src="/images/home/home-img-member.jpg"
              alt=""
              width="1120"
              height="300"
            />
          </picture>
        </div>
        <div className={styles.inner}>
          <section className={styles.principle}>
            <h1 className={styles.principle__title}>
              <span className={styles.principle__title__label}>行動規範</span>
              <span className={styles.principle__title__label__en}>PRINCIPLE</span>
            </h1>

            <div className={styles.principle__body}>
              <ul className={styles.principle__list}>
                <li className={styles.principle__itm}>
                  <h2 className={styles.principle__head}>
                    <span className={styles.principle__head__label}>行動者であれ</span>
                    <span className={styles.principle__head__label__en}>Be the doers.</span>
                  </h2>
                  <p className={styles.principle__itm__txt}>評論家ではなく、行動者になろう。自分や周りの気づいた点について、指摘でとどまる評論家ではなく、実際に行動を起こしていこう</p>
                </li>
                <li className={styles.principle__itm}>
                  <h2 className={styles.principle__head}>
                    <span className={styles.principle__head__label}>プロフェッショナルたれ</span>
                    <span className={styles.principle__head__label__en}>Be professional.</span>
                  </h2>
                  <p className={styles.principle__itm__txt}>プロとして高い水準で仕事を追求しよう。高い意識を持ち、プロに恥じない努力を重ね、そして結果を出す。 そういう主人公の集まる、プロスポーツチームのような集団であり続けよう。</p>
                </li>
                <li className={styles.principle__itm}>
                  <h2 className={styles.principle__head}>
                    <span className={styles.principle__head__label}>オーナーシップを持とう</span>
                    <span className={styles.principle__head__label__en}>Be the owner of your business.</span>
                  </h2>
                  <p className={styles.principle__itm__txt}>取り組んでいるすべての仕事を「自分ごと」化し、そのオーナーであり、その仕事単位の「経営者」であるという意識を持とう。結果にこだわろう。</p>
                </li>
                <li className={styles.principle__itm}>
                  <h2 className={styles.principle__head}>
                    <span className={styles.principle__head__label}>挑戦を楽しもう</span>
                    <span className={styles.principle__head__label__en}>Be the pioneer and love challenge.</span>
                  </h2>
                  <p className={styles.principle__itm__txt}>迷ったときは、いつも困難な方の道を選ぼう。そうすれば挑戦を終えた後、以前より進化した自分に出会うことができる。この新しい出会いの連続が快感となり、いつしか困難を楽しむことができるようになる。</p>
                </li>
                <li className={styles.principle__itm}>
                  <h2 className={styles.principle__head}>
                    <span className={styles.principle__head__label}>自身が幸せであれ、<br className="ex-onlysp" />そして周りを幸せにしよう</span>
                    <span className={styles.principle__head__label__en}>Be happy and generate happiness around you!</span>
                  </h2>
                  <p className={styles.principle__itm__txt}>まず、自分自身が小さなことに感謝し、常に幸せを感じられるような感性を持とう。そして周りの人を幸せにしたいという想いを持ち、実際に幸せを生み出し、それを仲間や家族、社会に還元しよう。 よりよい世界を実現しよう。</p>
                </li>
              </ul>
            </div>
          </section>
        </div>

        {
          currentProfile ? (
            <ProfileModal
              {...currentProfile}
              handleClickClose={handleClickModalClose}
            />
          ): ''
        }
      </main>
    </Layout>
  )
}

export default MemberPage
