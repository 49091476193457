import React, { useCallback, useMemo } from 'react'
import classNames from 'classnames/bind'

import styles from '@styles/components/profile_box.module.scss'

import IcnTriangle from '@comp/atom/icn_triangle'
import Nl2br from '@comp/util/nl2br'

const cx = classNames.bind(styles)

// markup
const ProfileBox = ({
  data,
  handleClick,
  isVisible
}) => {
  const onClick = useCallback( (evt) => {
    evt.preventDefault()
    handleClick(data.id);
  },[data.id, handleClick])

  const profImgSrc = useMemo( () => {
    return data.profimg ? `/images/member/member-img-${data.id}.jpg` : `/images/member/member-img-noimage.png`
  },[data])

  const classNameWrap = cx({
    wrap: true,
    isHidden: !isVisible
  })

  return (
    <li className={classNameWrap}>
      <a href="#" onClick={onClick} className={styles.btn}>
        <div className={styles.img}>
          <div className={styles.img__cover}>
            View Detail
            <span className={styles.img__icn}><IcnTriangle /></span>
          </div>
          <span className={styles.yummy}>
            {
              data.yummy ? <img src={`/images/member/yummy/${data.yummy}2.png`} alt={`${data.yummy} ${data.yummy}`} /> : null
            }
          </span>
          <img src={profImgSrc} alt={data.name} className={styles.img__prof}/>
        </div>         
      </a>
      <h2 className={styles.name}>{data.name}</h2>
      <p className={styles.position}><Nl2br txt={data.position} /></p>
      <p className={styles.info}>
        <span className={styles.info__label}>
          <span className={styles.info__label__txt}>INDUSTRY</span>
        </span>
        <span className={styles.info__txt}>
          <Nl2br txt={data.industry} />
        </span>
      </p>
      <p className={styles.info}>
        <span className={styles.info__label}>
          <span className={styles.info__label__txt}>BACK<br />GROUND</span>
        </span>
        <span className={styles.info__txt}>
          <Nl2br txt={data.background} />
        </span>
      </p>       
    </li>
  )
}

export default ProfileBox
